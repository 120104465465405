
    <template>
      <section class="content element-doc">
        <h2>InfiniteScroll 无限滚动</h2>
<p>滚动至底部时，加载更多数据。</p>
<h3>基础用法</h3>
<p>在要实现滚动加载的列表上上添加<code>v-infinite-scroll</code>，并赋值相应的加载方法，可实现滚动到底部时自动执行加载方法。</p>
<demo-block>
        
        <template #source><element-demo0 /></template>
        <template #highlight><pre v-pre><code class="html">&lt;template&gt;
  &lt;ul
    class=&quot;infinite-list&quot;
    v-infinite-scroll=&quot;load&quot;
    :infinite-scroll-delay=&quot;0&quot;
    style=&quot;overflow:auto&quot;
  &gt;
    &lt;li v-for=&quot;i in count&quot; class=&quot;infinite-list-item&quot;&gt;{{ i }}&lt;/li&gt;
  &lt;/ul&gt;
&lt;/template&gt;

&lt;script&gt;
  import { ref } from 'vue'
  export default {
    setup() {
      const count = ref(0)
      const load = () =&gt; {
        count.value += 2
      }
      return { count, load }
    }
  }
&lt;/script&gt;
</code></pre></template></demo-block><h3>禁用加载</h3>
<demo-block>
        
        <template #source><element-demo1 /></template>
        <template #highlight><pre v-pre><code class="html">&lt;template&gt;
  &lt;div class=&quot;infinite-list-wrapper&quot; style=&quot;overflow:auto&quot;&gt;
    &lt;ul
      class=&quot;list&quot;
      v-infinite-scroll=&quot;load&quot;
      :infinite-scroll-delay=&quot;0&quot;
      :infinite-scroll-disabled=&quot;disabled&quot;
    &gt;
      &lt;li v-for=&quot;i in count&quot; class=&quot;list-item&quot;&gt;{{ i }}&lt;/li&gt;
    &lt;/ul&gt;
    &lt;p v-if=&quot;loading&quot;&gt;加载中...&lt;/p&gt;
    &lt;p v-if=&quot;noMore&quot;&gt;没有更多了&lt;/p&gt;
  &lt;/div&gt;
&lt;/template&gt;

&lt;script&gt;
  import { ref, computed, getCurrentInstance } from 'vue'
  export default {
    setup() {
      const count = ref(10)
      const loading = ref(false)
      const self = getCurrentInstance().ctx

      const noMore = computed(() =&gt; {
        // console.log(self.count == count.value)
        return self.count &gt;= 20
      })

      const disabled = computed(() =&gt; {
        return self.loading || noMore.value
      })
      const load = () =&gt; {
        loading.value = true
        setTimeout(() =&gt; {
          count.value += 2
          loading.value = false
        }, 2000)
      }

      return {
        count,
        loading,
        noMore,
        disabled,
        load
      }
    }
  }
&lt;/script&gt;
</code></pre></template></demo-block><h3>Attributes</h3>
<table>
<thead>
<tr>
<th>参数</th>
<th>说明</th>
<th>类型</th>
<th>可选值</th>
<th>默认值</th>
</tr>
</thead>
<tbody>
<tr>
<td>infinite-scroll-disabled</td>
<td>是否禁用</td>
<td>boolean</td>
<td>-</td>
<td>false</td>
</tr>
<tr>
<td>infinite-scroll-delay</td>
<td>节流时延，单位为 ms</td>
<td>number</td>
<td>-</td>
<td>200</td>
</tr>
<tr>
<td>infinite-scroll-distance</td>
<td>触发加载的距离阈值，单位为 px</td>
<td>number</td>
<td>-</td>
<td>0</td>
</tr>
<tr>
<td>infinite-scroll-immediate</td>
<td>是否立即执行加载方法，以防初始状态下内容无法撑满容器。</td>
<td>boolean</td>
<td>-</td>
<td>true</td>
</tr>
</tbody>
</table>

      </section>
    </template>
    <script>
      import hljs from 'highlight.js'
      import * as Vue from "vue"
      export default {
        name: 'component-doc',
        components: {
          "element-demo0": (function() {
    
    const { renderList: _renderList, Fragment: _Fragment, openBlock: _openBlock, createBlock: _createBlock, toDisplayString: _toDisplayString, createVNode: _createVNode, resolveDirective: _resolveDirective, withDirectives: _withDirectives } = Vue

function render (_ctx, _cache) {
  const _directive_infinite_scroll = _resolveDirective("infinite-scroll")

  return _withDirectives((_openBlock(), _createBlock("ul", {
    class: "infinite-list",
    "infinite-scroll-delay": 0,
    style: {"overflow":"auto"}
  }, [
    (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.count, (i) => {
      return (_openBlock(), _createBlock("li", { class: "infinite-list-item" }, _toDisplayString(i), 1))
    }), 256))
  ], 512)), [
    [_directive_infinite_scroll, _ctx.load]
  ])
}
  
    
        const { ref } = Vue
        
  const democomponentExport = {
    setup() {
      const count = ref(0)
      const load = () => {
        count.value += 2
      }
      return { count, load }
    }
  }
    return {
      mounted(){
        this.$nextTick(()=>{
          const blocks = document.querySelectorAll('pre code:not(.hljs)')
          Array.prototype.forEach.call(blocks, hljs.highlightBlock)
        })
      },
      render,
      ...democomponentExport
    }
  })(),"element-demo1": (function() {
    
    const { renderList: _renderList, Fragment: _Fragment, openBlock: _openBlock, createBlock: _createBlock, toDisplayString: _toDisplayString, createVNode: _createVNode, resolveDirective: _resolveDirective, withDirectives: _withDirectives, createCommentVNode: _createCommentVNode } = Vue

function render (_ctx, _cache) {
  const _directive_infinite_scroll = _resolveDirective("infinite-scroll")

  return (_openBlock(), _createBlock("div", {
    class: "infinite-list-wrapper",
    style: {"overflow":"auto"}
  }, [
    _withDirectives(_createVNode("ul", {
      class: "list",
      "infinite-scroll-delay": 0,
      "infinite-scroll-disabled": _ctx.disabled
    }, [
      (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.count, (i) => {
        return (_openBlock(), _createBlock("li", { class: "list-item" }, _toDisplayString(i), 1))
      }), 256))
    ], 8, ["infinite-scroll-disabled"]), [
      [_directive_infinite_scroll, _ctx.load]
    ]),
    (_ctx.loading)
      ? (_openBlock(), _createBlock("p", { key: 0 }, "加载中..."))
      : _createCommentVNode("", true),
    (_ctx.noMore)
      ? (_openBlock(), _createBlock("p", { key: 1 }, "没有更多了"))
      : _createCommentVNode("", true)
  ]))
}
  
    
        const { ref, computed, getCurrentInstance } = Vue
        
  const democomponentExport = {
    setup() {
      const count = ref(10)
      const loading = ref(false)
      const self = getCurrentInstance().ctx

      const noMore = computed(() => {
        // console.log(self.count == count.value)
        return self.count >= 20
      })

      const disabled = computed(() => {
        return self.loading || noMore.value
      })
      const load = () => {
        loading.value = true
        setTimeout(() => {
          count.value += 2
          loading.value = false
        }, 2000)
      }

      return {
        count,
        loading,
        noMore,
        disabled,
        load
      }
    }
  }
    return {
      mounted(){
        this.$nextTick(()=>{
          const blocks = document.querySelectorAll('pre code:not(.hljs)')
          Array.prototype.forEach.call(blocks, hljs.highlightBlock)
        })
      },
      render,
      ...democomponentExport
    }
  })(),
        }
      }
    </script>
  